import './src/styles/layout.scss';

export const onRouteUpdate = ({ location }) => {
  // Check if the path is the specific page
  if (location.pathname === '/p/creditkarma/mortgage-rates/') {
    // Avoid duplicate font link elements
    if (!document.getElementById('google-font-lato')) {
      const link = document.createElement('link');
      link.id = 'google-font-lato';
      link.href = 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap';
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    }
  } else {
    // Optionally remove the font when navigating away from the page
    const latoLink = document.getElementById('google-font-lato');
    if (latoLink) {
      latoLink.parentNode.removeChild(latoLink);
    }
  }
};
